<template>
  <div class="header">
    <router-link :to="'/'">Baugenossenschaft Wiedikon</router-link>
  </div>
</template>

<script>

export default {
  name: 'Header',
}
</script>

<style scoped>
.header {
  font-size: 5.53vw;
  text-transform: uppercase;
  padding: 0.5vw;
  background-color: #fff;
}
a { text-decoration: none; color: #000; }
@media (min-aspect-ratio: 12/10) {
  .header {
    text-align: center;
  }
}
</style>
