<template>
  <div class="accordionsection" :class="[titleSlug]">
    <div class="accheader" @click="openAccordion()" :key="titlePart.accordionTitle" :class="[{open: open}]">
      <span v-html="titlePart.accordionTitle"></span>
      <svg xmlns="http://www.w3.org/2000/svg" width="34.336" height="18.582" viewBox="0 0 34.336 18.582">
        <path id="Path_20" data-name="Path 20" d="M3069.188,1852.762l16.461,16.461,16.461-16.461" transform="translate(-3068.481 -1852.055)" fill="none" stroke="#000" stroke-width="2"/>
      </svg>
    </div>
    <div class="acc-content-container" v-if="open">
      <div class="acc-content" v-for="part in section">
        <div class="acc-text" :class="{twocols : part.nrCols }" v-if="part.__typename == 'accordionSection_accordionText_BlockType'" v-html="part.accordionText"></div>
        <img v-if="part.__typename == 'accordionSection_accordionImage_BlockType'" :src="part.accordionImage[0].url" />
        <div class="acc-docs" v-if="part.__typename == 'accordionSection_documents_BlockType'">
          <div class="doc" v-for="doc in part.documents">
            <a :href="doc.url" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24.469" height="30.381" viewBox="0 0 24.469 30.381">
                <g id="Group_59" data-name="Group 59" transform="translate(-685 -1190)">
                  <g id="Path_21" data-name="Path 21" transform="translate(685 1190)" fill="none">
                    <path d="M9.467,0h15V30.381H0V9.865Z" stroke="none"/>
                    <path d="M 10.31955432891846 1.999998092651367 L 1.999994277954102 10.66986656188965 L 1.999994277954102 28.38065719604492 L 22.4687442779541 28.38065719604492 L 22.4687442779541 1.999998092651367 L 10.31955432891846 1.999998092651367 M 9.466864585876465 -1.9073486328125e-06 L 24.4687442779541 -1.9073486328125e-06 L 24.4687442779541 30.38065719604492 L -5.7220458984375e-06 30.38065719604492 L -5.7220458984375e-06 9.865488052368164 L 9.466864585876465 -1.9073486328125e-06 Z" stroke="none" fill="#000"/>
                  </g>
                  <path id="Path_22" data-name="Path 22" d="M-2174.4,1381.827h9.691V1371.8" transform="translate(2860.067 -180.91)" fill="none" stroke="#000" stroke-width="2"/>
                </g>
              </svg>
              <div class="doc-infos">
                <div class="doc-title">{{ doc.title }}</div>
                <div class="doc-smallinfos">{{ doc.extension }} / {{ humanFileSize(doc.size) }} </div>
              </div>
            </a>
          </div>
        </div>

        <iframe id="repairform" class="repairform" :class="{loading: loadingIframe}" v-if="titlePart.accordionTitle == 'Reparaturformular'" src="https://repairform.bgwiedikon.ch/form/" @load="notLoading()"></iframe>
        <div class="iframe-loading" v-if="titlePart.accordionTitle == 'Reparaturformular' && loadingIframe == true">Lädt ...</div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Accordion',
  props: {
    section: {
      required: true,
    }
  },
  data() {
    return {
      open: false,
      loadingIframe: true,
    }
  },
  computed: {
    titlePart() {
      return this.section[0]
    },
    titleSlug() {
      return this.section[0].accordionTitle
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
    },
    contentParts() {
      return this.section.slice().splice(1, this.section.length)
    }
  },
  methods: {
    humanFileSize(size) {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },
    openAccordion() {
      this.loadingIframe = true
      //normally, just open it.
      //if mobile and if the text page is not in position 'stuckBottom', do nothing
      if((this.$parent.isVertical && this.$parent.accordionPosition == 'stuckBottom' ) || this.$parent.isVertical == false){
        this.open = !this.open
      }
    },
    notLoading() {
      this.loadingIframe = false
    }
  },
  created() {
    window.onmessage = (e) => {
      if (e.data.hasOwnProperty("frameHeight")) {
        document.getElementById("repairform").style.height = `${e.data.frameHeight + 30}px`;
      }
    };
  }
}
</script>

<style scoped>
.doc a{
  text-decoration: none;
  color: #000;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-column-gap: 0.2rem;
}
.doc {
  margin-bottom: 0.5rem;
}
svg {
  width: 80%;
  height: auto;
}
.doc a:hover {
  text-decoration: underline;
}
.doc-smallinfos {
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-top: 0.1rem;
}
.accheader {
  font-size: clamp(1rem, 7vw, 1.8rem);
  text-transform: uppercase;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1.8rem;
  align-items: center;
}
.accheader svg {
  width: 100%;
}
.accheader.open svg {
  transform: rotate(180deg);
}
.accordionsection {
  border-top: 3px solid #000;
}
.acc-content-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
>>> p {
  margin-top: 0;
}
img {
  max-width: 80%;
  margin-bottom: 0.5rem;
}
@media (min-aspect-ratio: 12/10) {
  .twocols {
    column-count: 2;
    column-gap: 1rem;
  }
  img { max-height: 40vw; }
}
@media(max-aspect-ratio: 12/10) {
  .accordionsection {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  img { max-height: 100vw; }
}
.repairform {
  width: 100%;
  border: none;
  //min-height: 28rem;
}
.repairform.loading {
  height: 0;
  min-height: 0;
}
.iframe-loading {
  //background-color: red;
}
.vorstand >>> p{
  max-width: 20rem;
}
</style>
