<template>
<div class="page baublog" key="bau">
  <div class="webcam">
    <div class="feed-one" :class="[activeFeed, activeView]">
      <div class="still">
          <div class="resp-container">
            <iframe class="resp-iframe" src="https://feed.yellow.camera/ZMHNSMAOP"></iframe>
          </div>
        <!--<yellow-feed token="ZMHNSMAOP"></yellow-feed>--></div>
      <div class="timeline">
          <div class="resp-container">
            <iframe class="resp-iframe" src="https://feed.yellow.camera/WQ2BUT9KC?autoplay=true&loop=true&controls=false&scaler=fitwidth"></iframe>
          </div>
        </div>
    </div>
    <div class="feed-two" :class="[activeFeed, activeView]">
      <div class="still">
          <div class="resp-container second-last">
            <iframe class="resp-iframe" src="https://feed.yellow.camera/JSTFQ6KAS"></iframe>
          </div>
        </div>
      <div class="timeline">
          <div class="resp-container last">
            <iframe class="resp-iframe" src="https://feed.yellow.camera/8KC11S1GF?autoplay=true&loop=true&controls=false&scaler=fitwidth"></iframe>
          </div>
      </div>
    </div>
    <div id="buttons" :class="[activeFeed, activeView]">
      <div class="left">
        <div class="btn-container">
          <div class="btn" id="timeline" @click="changeView('timeline')">zeitraffer</div>
          <div class="btn" id="still" @click="changeView('still')">still</div>
          <div class="btn" v-if="!isVertical" :class="{ active: blogVisible }" id="blogbtn" @click="blogVisible = !blogVisible">News</div>
        </div>
      </div>
      <div class="right">
        <div class="btn-container">
          <div class="btn" id="feedone" @click="changeFeed('feedone')">kam. 2</div>
          <div class="btn" id="feedtwo" @click="changeFeed('feedtwo')">kam. 1</div>
        </div>
      </div>
      <div class="blogbutton-outside">
        <div class="btn" v-if="isVertical" :class="{ active: blogVisible }" id="blogbtn" @click="blogVisible = !blogVisible">News</div>
      </div>
    </div>
  </div>
  <div class="textPage static" ref="textPage" v-if="blogVisible">
    <div class="text-container">
    <div class="btn" v-if="isVertical" :class="{ active: blogVisible }" id="blogbtn" @click="blogVisible = !blogVisible">News</div>
    <Intro v-if="activePage && (activePage.intro || activePage.introSmall)" :bigText="activePage.intro" :smallText="activePage.introSmall" />
    <div v-for="mysection in accordions">
      <div v-for="mysectioni in mysection">
        <h3 v-if="mysectioni.__typename == 'accordionSection_accordionTitle_BlockType' && mysectioni.accordionTitle !== '&nbsp;'" v-html="mysectioni.accordionTitle"></h3>
        <div v-if="mysectioni.__typename == 'accordionSection_accordionText_BlockType'" v-html="mysectioni.accordionText"></div>
        <img v-if="mysectioni.__typename == 'accordionSection_accordionImage_BlockType'" :src="mysectioni.accordionImage[0].url" />
      </div>
    </div>
    <!--<Accordions />-->
    <!-- form https://www.freecodecamp.org/news/send-emails-from-your-vue-application/ -->
    </div>
  </div>
</div>
</template>

<script>
import Intro from './Intro.vue'
import Accordion from './Accordion.vue'

export default {
  name: 'Webcam',
  components: {
    Intro,
    Accordion
  },
  beforeRouteEnter (to, from, next) {
    console.log('from')
    console.log(from.name)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => {
      // access to component instance via `vm`
      console.log(from.name)
      if(from.name !== null){
        //setTimeout(function(){
          vm.reloadPage()
        //}.bind(vm), 2000);
      }
    })
  },
  props: {
    pagesData: {
      required: true
    },
    isVertical: {
      required: true
    }
  },
  data: function () {
    return {
      activeFeed: 'feedtwo',
      activeView: 'still',
      blogVisible: false,
    }
  },
  computed: {
    activePage() {
      let slug = 'baublog'
      var filteredArray = this.pagesData.filter(function(page){
        return page.slug == slug
      })
      return filteredArray[0]
    },
    accordions() {
      //transforms unconnected sections into accordions
      if(this.activePage) {
        let i;
        let accInitArr = this.activePage.accordionSection
        let accArr = [] //should make an array of accordions.
        for (i = 0; i < accInitArr.length; i++) {
          if(accInitArr[i].__typename == 'accordionSection_accordionTitle_BlockType') {
            let newAccSect = []
            newAccSect.push(accInitArr[i])
            accArr.push(newAccSect)
          } else {
            //add to existing last el
            if(accInitArr[accArr.length - 1]) {
              accArr[accArr.length - 1].push(accInitArr[i])
            } else {
              //if there is no title and it's the only thing
              let blankTitle = {
                accordionTitle: "&nbsp;",
                id: "0",
                __typename: "accordionSection_accordionTitle_BlockType"
              }
              let newAccSect = []
              newAccSect.push(blankTitle)
              accArr.push(newAccSect)
              accArr[accArr.length - 1].push(accInitArr[i])
            }
          }
        }
        console.log(accArr)
        return accArr
      } else {
        return null
      }
    },
  },
  methods: {
    changeFeed(whichfeed) {
      this.activeFeed = whichfeed
    },
    changeView(whichview) {
      this.activeView = whichview
      //autoplay videos so they show up in safari...
      if(this.activeView == 'timeline') {
        let videos = document.getElementsByTagName("video")
        for (let i = 0; i < videos.length; i++) {
          videos[i].setAttribute("playsinline", "playsinline");
          videos[i].setAttribute("muted", "muted");
          videos[i].setAttribute("loop", "loop");
          videos[i].play()
        }
      }
    },
    reloadPage() {
      window.location.reload()
    }
  },
  mounted() {
    //ridiculous: i can only get this to work if entire page reloads. not very nice.
    /*let elToCheck = document.getElementById('yellow')
    if(elToCheck == null){
      let webcamscript = document.createElement('script')
      webcamscript.setAttribute("id", "yellow")
      webcamscript.setAttribute('src', 'https://feed.yellow.webcam/js/yellow-feedplugin.js')
      this.$el.append(webcamscript)
    }
    console.log('webcam')
    console.log(this)*/
  },
}
</script>

<style>
/*.webcam { height: 100%; }
iframe {
  width: 100%;
  height: 100%;
}*/
.image-container {
}
.image-container img.image, .video-player {
  width: 100% !important;
  height: calc(100vh - 12vw) !important;
  object-fit: cover;
  object-position: bottom center;
}
.branding-container { display: none; }
.feed-one, .feed-two, .still, .timeline { display: none; }
.feedone.feed-one { display: block; }
.feedtwo.feed-two { display: block; }
.still .still { display: block; }
.timeline .timeline { display: block;}

#buttons .btn, #blogbtn {
  background: #fff;
  text-align: center;
  border-radius: 500px;
  text-transform: uppercase;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 1.3rem;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.1);
}

#buttons.feed-one, #buttons.feed-two, #buttons.still, #buttons.timeline { display: block; color: #000; }
/*#buttons.feed-one .btn, #buttons.feed-two .btn, #buttons.still .btn, #buttons.timeline .btn { border: 2px solid #b5b5b5; }*/
#buttons.feedone #feedone, #buttons.feedtwo #feedtwo, #buttons.still #still, #buttons.timeline #timeline { color: #000; background: #c1c1c1; /*border: 2px solid #000;*/ }

#buttons #still, #buttons #feedtwo {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 0;
}
#buttons #timeline, #buttons #feedone {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

#buttons .left {
  position: absolute;
  left: 0;
  top: 7vw;
  height: calc(100vh - 10rem - 12vw);
}
#buttons .right {
  position: absolute;
  right: 22vw;
  top: 7vw;
  height: calc(100vh - 10rem - 12vw);
}
#buttons .btn-container {
  transform: rotate(-90deg) translate(calc(-50vh - 3vw + 9rem), -8.5rem);
  width: 23rem;
}
#buttons .right .btn-container {
  transform: rotate(-90deg) translate(calc(-50vh - 3vw + 9rem), 5.5rem);
  width: 17rem;
}
#buttons .left, #buttons .right { max-width: 2rem; }

@media (max-aspect-ratio: 12/10) {
  #buttons .left, #buttons .right { width: 5rem; top: 20vw; }
  #buttons .btn-container {
    transform: rotate(-90deg) translate(calc(-50vh + 5rem), -5.5rem);
    width: 17rem;
  }
  #buttons .right .btn-container {
    transform: rotate(-90deg) translate(calc(-50vh + 7rem), -5.5rem);
  }
  #buttons .left {
    left: -0.5rem;
  }
  #buttons .right {
    right: 0.5rem;
  }
  .image-container img.image, .video-player {
    /*ignores mac bar.*/
    height: calc(100vh - 20vw) !important;
  }
}

/*horiz phone, maybe wont do much*/
@media (min-aspect-ratio: 12/10) and (max-width: 700px) {
  #buttons .btn {
    background: #fff;
    text-align: center;
    border-radius: 500px;
    text-transform: uppercase;
    padding: 0.5rem;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
    font-size: 0.8rem;
  }
  #buttons .left, #buttons .right { width: 17rem; top: 12vw;}
  #buttons .right { top: 8vw; }
  #buttons .btn-container {
    transform: rotate(-90deg) translate(calc(-50vh - 3vw + 9rem), -8.5rem);
  }
  #buttons .left .btn-container { width: 21rem; }
  #buttons .right .btn-container {
    transform: rotate(-90deg) translate(calc(-50vh - 3vw + 9rem), 6.5rem);
  }
}


/*blog*/
@media (min-aspect-ratio: 12/10) {
  .baublog {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    overflow-y: hidden;
  }
  .webcam {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  .webcam .textPage {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    z-index: 2;
    background: #fff;
    overflow-y: scroll;
  }
}
#blogbtn {
  margin-left: 1rem;
}
.webcam .text-container{
  padding: 0 0.3rem;
}
#buttons #blogbtn {
  /*text-transform: lowercase;*/
  min-width: 1.5rem;
}
#buttons #blogbtn.active, #blogbtn.active {
  color: #000;
  background: #c1c1c1;
}
.webcam .textPage img {
    max-height: 40vw;
    max-width: 80%;
    margin-bottom: 0.5rem;
}
.webcam .textPage h3 {
  text-transform: uppercase;
}
@media (max-aspect-ratio: 12/10) {
  .webcam .textPage {
    position: absolute;
    top: 20vw;
    background: #fff;
    overflow-y: scroll;
    height: calc(100%);
    padding: 0.4rem;
    padding-top: 2vw;
    padding-bottom: 8vw;
  }
  .webcam .textPage .text-container {
    padding-bottom: 30vw;
  }
  .webcam .intro { padding-left: 0; }
  #blogbtn { position: absolute; top: 22vw; z-index: 0; left: -0.2rem; }
  .webcam .textPage #blogbtn { position: static; margin-left: 0rem; margin-top: 0.2rem; }
  .webcam .textPage img {
    max-width: 80%;
    max-height: 100vw;
  }
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    margin-bottom: -42%;        /* <- Adjust spacing between bottom of iframe and following content */
}
.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
@media  (max-aspect-ratio: 15/10) {
  .feed-one, .feed-two, .still, .timeline { display: block; }
  .last { padding-top: 60%; }
  .baublog { overflow-y: auto; }
  #buttons #timeline, #buttons #still, #buttons #feedone, #buttons #feedtwo { display: none; }
}

</style>
