<template>
  <div
    class="menu"
    :class="[{ menucollapsed: collapsedClass }, { menuopen: !collapsedClass }]"
  >
    <Home
      v-if="isVertical"
      :homeData="homeData"
      :isHome="isHome"
      :showNews="showNews"
      ref="verticalHome"
      @click.native="toggleItems()"
    />
    <!--instead, use grid to switch positions of home and menu?-->
    <transition name="menutransition">
      <ul
        class="menuitems"
        v-if="!isVertical || (isVertical && showItems) || isHome"
      >
        <li class="goHome" v-if="isVertical" @click="toggleItems()">
          <router-link v-if="showItems" :to="'/'"
            >Baugenossenschaft Wiedikon</router-link
          >
          <div v-else>Baugenossenschaft Wiedikon</div>
          <svg
            id="uparrow"
            width="116.503"
            height="174.774"
            viewBox="0 0 116.503 174.774"
            v-if="isVertical"
          >
            <g
              id="Group_4"
              data-name="Group 4"
              transform="translate(-435.426 -343.784)"
            >
              <line
                id="Line_1"
                data-name="Line 1"
                y2="172.058"
                transform="translate(493.677 346.5)"
                fill="none"
                stroke="#000"
                stroke-width="3"
              />
              <path
                id="Path_1"
                data-name="Path 1"
                d="M438.154,432.657l57-86.029,57,86.029"
                transform="translate(-1.477 -0.128)"
                fill="none"
                stroke="#000"
                stroke-width="3"
              />
            </g>
          </svg>
        </li>
        <div class="menusubitems" :class="{ collapsed: collapsedClass }">
          <li
            :class="{ active: $route.params.slug == item.slug }"
            v-show="(!isVertical && (showItems || showItemsInit)) || isVertical"
            v-for="(item, itemname) in navDataClean"
            v-if="item.length > 0"
            v-bind:key="itemname"
            @click="showSubmenu(itemname)"
          >
            <template v-if="itemname !== 'newsDirectLink' && item.length > 0">
              <span>
                {{
                  itemname == "navGenossenschaft"
                    ? "Genossenschaft"
                    : itemname == "navWohnen"
                    ? "Wohnen"
                    : itemname == "navNews" || itemname == "newsDirectLink"
                    ? "News"
                    : itemname
                }}
              </span>
            </template>
            <router-link
              v-if="itemname == 'newsDirectLink' && !!item"
              to="/news"
              @click.native="toggleItems()"
              >{{ "News" }}</router-link
            >
            <div class="submenu" v-if="openMenu == itemname && item.length > 0">
              <router-link
                v-for="subitem in item"
                :key="subitem.linkPath"
                :to="subitem.linkPath"
                @click.native="toggleItems()"
                ><span>{{ subitem.linkTitle }}</span></router-link
              >
            </div>
          </li>
          <li
            v-if="!isVertical"
            class="repair"
            :class="{ active: $route.name == 'Reparaturen' }"
          >
            <router-link to="reparaturen">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="531.026"
                height="531.141"
                viewBox="0 0 531.026 531.141"
              >
                <g
                  id="Group_151"
                  data-name="Group 151"
                  transform="translate(-10291 -5122)"
                >
                  <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M109.794,0c6.708,1.622,13.561,2.8,20.1,4.942,34.776,11.387,59.559,44.784,59.6,81.28.01,10.139-2.193,20.352-4.152,30.381-.644,3.3.284,4.835,2.224,6.777q40.029,40.071,80,80.2Q336.961,273.2,406.286,342.9c2.793,2.813,5.113,3.56,9.035,2.356,51.381-15.767,105.373,17.4,114.514,70.178,2.567,14.818.9,33.8-4.162,45.527-1.254-1.152-2.435-2.162-3.532-3.257-12.834-12.807-25.475-25.813-38.519-38.4A45.456,45.456,0,0,0,407.7,440.836c-4.189,16.443.206,30.975,11.884,43.065,12.363,12.8,25.105,25.235,37.719,37.792a41.174,41.174,0,0,0,3.921,3.083q-.171.944-.343,1.886c-7.64,1.417-15.225,3.484-22.93,4.144a88.891,88.891,0,0,1-92.831-62.488,86.17,86.17,0,0,1,.164-52.893c1.023-3.226.773-5.275-1.749-7.793Q233.511,297.829,123.7,187.806c-2.178-2.182-3.967-2.6-6.934-1.72C67.5,200.635,17.9,174.158,3.665,125.713,2.043,120.192,1.2,114.44,0,108.8V92.826Q2.529,81.77,5.057,70.712l1.663-.3c1.164,1.4,2.225,2.9,3.505,4.188,12.435,12.482,24.794,25.041,37.4,37.349,11.637,11.363,25.724,15.657,41.6,12.162,17.511-3.856,29.256-14.911,34.184-31.988C128.347,75,123.91,59.642,111.293,46.9c-12.278-12.4-24.669-24.693-37-37.045C73.054,8.61,71.9,7.279,69.952,5.175L92.826,0Z"
                    transform="translate(10291 5122)"
                    fill="#1a1818"
                  />
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M158.044,265.41,266.439,373.863c-1.236.69-3.322,1.334-4.694,2.7q-70.252,69.764-140.383,139.65c-15.2,15.13-31.95,15.094-47.1-.053q-29.81-29.794-59.6-59.606C-.3,441.58-.3,424.83,14.633,409.828q69.669-70,139.333-140c1.4-1.412,2.705-2.928,4.078-4.422M43.947,434.168c1.411,2.856,2.167,5.471,3.755,7.4,5.309,6.463,12.891,6.1,19.8-.8q49.908-49.841,99.762-99.734a32.476,32.476,0,0,0,2.706-2.924c4.187-5.369,3.449-11.6-1.892-16.378-4.9-4.388-10.854-4.434-15.584.284q-52.05,51.927-103.921,104.033c-2.057,2.071-3.023,5.225-4.63,8.114M83.62,473.129c1.432,3.282,2.042,5.767,3.437,7.68,4.631,6.349,12.679,6.8,18.606,1.022,8.453-8.243,16.731-16.666,25.08-25.015q38.278-38.277,76.564-76.548c3.427-3.424,6.081-7.037,4.63-12.293-2.533-9.181-12.231-12.47-19.511-6.423-3.059,2.541-5.758,5.521-8.58,8.342Q136.917,416.808,90.068,463.8c-2.642,2.659-4.352,6.244-6.448,9.328"
                    transform="translate(10291 5122)"
                    fill="#1a1818"
                  />
                  <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M334.916,227.433,303.81,196.269c1.594-1.074,4.1-2.238,5.934-4.074,37.373-37.4,74.575-74.965,112.089-112.219A30.745,30.745,0,0,0,431.154,62.6a159.373,159.373,0,0,1,4.1-18.406c.771-2.609,2.524-5.6,4.729-7.005,17.335-11.07,34.9-21.776,52.362-32.651,3.8-2.366,6.929-2.133,10.162,1.2q11.453,11.819,23.282,23.267c3.085,3,3.059,5.841.916,9.28-10.964,17.6-21.745,35.318-32.9,52.8-1.406,2.2-4.437,3.967-7.057,4.685-7.494,2.054-15.249,3.162-22.726,5.263a22.683,22.683,0,0,0-9.638,5.243Q395.975,164.6,337.9,223.271a29.449,29.449,0,0,0-2.989,4.162"
                    transform="translate(10291 5122)"
                    fill="#1a1818"
                  />
                  <rect
                    id="Rectangle_67"
                    data-name="Rectangle 67"
                    width="531.026"
                    height="531.141"
                    transform="translate(10291 5122)"
                    fill="none"
                  />
                </g>
              </svg>
            </router-link>
          </li>
          <li
            v-else
            class="repair"
            :class="{ active: $route.name == 'Reparaturen' }"
            @click="toggleItems()"
          >
            <router-link to="reparaturen">Reparaturen </router-link>
          </li>
        </div>
      </ul>
    </transition>
  </div>
</template>

<script>
import Home from "./Home.vue";

export default {
  name: "Menu",
  components: {
    Home,
  },
  props: {
    pagesData: {
      required: true,
    },
    homeData: {
      required: true,
    },
    isHome: {
      required: true,
    },
    navData: { required: true },
    isVertical: { required: true },
    window: { required: true },
    showNews: { required: false },
    //should have an optional prop to show or not show the optional ws for mobile, adn this should be controlled by the menu.
  },
  data: function () {
    return {
      showItems: false,
      collapsedClass: true,
      openMenu: null,
    };
  },
  computed: {
    showItemsInit() {
      if (!this.isVertical) {
        return true;
      }
    },
    navDataClean() {
      let navDataClean = this.navData;
      delete navDataClean.__typename;
      return navDataClean;
    },
  },
  methods: {
    showSubmenu(menuName) {
      if (!this.openMenu || this.openMenu !== menuName) {
        this.openMenu = menuName;
      } else {
        this.openMenu = null;
      }
      this.toggleItems;
    },
    toggleItems() {
      if (this.showItems) {
        this.collapsedClass = true;
        setTimeout(
          function () {
            this.showItems = false;
          }.bind(this),
          400
        );
      } else {
        (this.collapsedClass = false), (this.showItems = true);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}
ul {
  list-style: none;
  padding-left: 0;
  margin: 0 0.5rem;
}
li {
  text-transform: uppercase;
  cursor: pointer;
}
.repair svg {
  height: 2rem;
  width: auto;
}
.repair {
  position: relative;
}
@media (min-aspect-ratio: 12/10) {
  .repair.active:after,
  .repair:hover:after {
    content: " ";
    width: 100%;
    background: #000;
    position: absolute;
    bottom: 0.3rem;
    height: 0.25rem;
    display: block;
  }
}
@media (max-aspect-ratio: 12/10) {
  #uparrow {
    width: 1.3rem;
    height: auto;
    position: absolute;
    right: 0rem;
    top: 0.75rem;
    transition: 0.2s all;
  }
  #uparrow line,
  #uparrow path {
    stroke-width: 0.4em;
  }
  .menuopen #uparrow {
    transform: scaleY(-1);
  }
  .goHome {
    position: relative;
  }
  .menu {
    display: grid;
    grid-template-rows: 1fr fit-content(100%);
    z-index: 600;
    width: 100%;
    transition: all 0.5s;
    position: absolute;
    height: 100%;
  }
  .singlepage .menu {
    height: 20vw;
    position: absolute;
  }
  .singlepage .menuitems {
    background-color: #fff;
    /*position: absolute;
    bottom: 0;*/
    /*transition: opacity 0.5s;
    transition-delay: 0.5s;*/
    //transition: height 0.5s;
    z-index: 400;
  }
  .singlepage .menu.menuopen {
    height: 100%;
    /*grid-row: 1 / 3;
    grid-column: 1 / 2;*/
    position: absolute;
    width: 100vw;
  }
  .singlepage .menu.menucollapsed {
    transition-delay: 0.2s;
  }
  .singlepage .home-and-news {
    //position: absolute;
    z-index: 600;
    min-height: 20vw;
  }
  .home-and-news {
    width: 100%;
    /*transition: all .5s;*/
    background-color: #fff;
  }
  .singlepage .menu.menuopen .home-and-news {
    /*has to be calculated w javascript i guess*/
    //height: calc(100vh - clamp(4rem, 58vw, 58vw) + 6vw);
    min-height: 20vw;
  }
  li {
    font-size: 7vw;
    padding: 0.5rem 0;
  }
  ul {
    margin: 0;
    padding: 0.5rem;
  }
  .home .menusubitems.collapsed {
    max-height: 0;
  }
  .menusubitems {
    transition: all 0.4s ease-in-out;
    max-height: 90vw;
    overflow: hidden;
  }
  .singlepage .menusubitems {
    transition-property: none;
  }
  /*.singlepage .menu.menucollapsed .menuitems { display: none; }*/
  .singlepage .menu .home-and-news {
    overflow: hidden;
  }
  .home .menuitems {
    border-top: 3px solid #000;
  }
}
li > span {
  display: inline-block;
}
@media (min-aspect-ratio: 12/10) {
  .menu {
    background-color: #fff;
  }
  ul {
    display: grid;
    grid-template-columns: fit-content(80%) fit-content(80%) fit-content(80%) fit-content(
        80%
      );
    column-gap: 0.3rem;
    justify-content: center;
  }
  .menusubitems {
    display: contents;
  }
  li:hover > span,
  a:hover {
    text-decoration: underline;
  }
  li:after {
    content: " /";
  }
  li:last-of-type:after {
    content: "";
  }
  li {
    font-size: 4.7vw;
    position: relative;
  }
}
@media (min-aspect-ratio: 12/10) and (max-width: 700px) {
  li {
    font-size: 4.7vw;
  }
}
@media (max-width: 1260px) {
  li {
    font-size: 4.6vw;
  }
}
@media (max-width: 1000px) {
  li {
    font-size: 4.5vw;
  }
}

/*.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  //transition-delay: 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}*/
.menutransition-enter-active,
.menutransition-leave-active {
  transition: all 0.5s;
  //transition-delay: 0.5s;
  overflow: hidden;
}
.menutransition-enter {
  transform: translate(0, -500%);
  max-height: 0;
}
.menutransition-leave-to {
  transform: translate(0, -500%);
  max-height: 100vh;
}
/*submenus */
@media (min-aspect-ratio: 12/10) {
  .submenu {
    position: absolute;
    bottom: 3.5rem;
    font-size: 1.5rem;
    width: 100%;
    background-color: #fff;
    z-index: 600;
  }
  .submenu a {
    display: block;
    padding: 0.5rem 0.2rem;
    border-bottom: 3px solid #000;
    cursor: pointer;
    text-decoration: none;
  }
  .submenu a:hover {
    text-decoration: none;
  }
  .submenu a:last-of-type {
    border-bottom: 0;
  }
}
@media (max-aspect-ratio: 12/10) {
  .submenu a {
    display: block;
    font-size: 2rem;
    padding-left: 1rem;
  }
}
@media (max-aspect-ratio: 12/10) and (max-width: 700px) {
  .submenu a {
    display: block;
    font-size: 7vw;
  }
}
</style>
