<template>
  <div class="page" :key="uniquePageName()"><!--key important for transitions-->
    <Intro v-if="isVertical && activePage && (activePage.intro || activePage.introSmall)" :bigText="activePage.intro" :smallText="activePage.introSmall" @click.native="downAccordions()" />
    <imageSequence ref="imageSequencePage" v-if="activePage && activePage.pageImgs" :pageImgs="activePage.pageImgs" :isVertical="isVertical" @click.native="downAccordions()" />
    <div class="textPage" ref="textPage" :class="textPageClasses" @click="showAccordions()">
      <div class="text-container">
      <Intro v-if="!isVertical && activePage && (activePage.intro || activePage.introSmall)" :bigText="activePage.intro" :smallText="activePage.introSmall" />
      <Accordion v-if="activePage && accordions" v-for="section in accordions" :section="section" />
      <!--<Accordions />-->
      <!-- form https://www.freecodecamp.org/news/send-emails-from-your-vue-application/ -->
      </div>
    </div>
  </div>
</template>

<script>
import imageSequence from './imageSequence.vue'
import Intro from './Intro.vue'
import Accordion from './Accordion.vue'

export default {
  name: 'Page',
  components: {
    imageSequence,
    Intro,
    Accordion
  },
  props: {
    pagesData: {
      required: true
    },
    isVertical: {
      required: true
    }
  },
  data: function() {
    return {
      accordionPosition: 'static', //static, farDown, stuckVisible, stuckBottom
    }
  },
  watch: {
    $route (to, from) {
      // react to route changes...
      //get el to scroll
      /*let elToScroll = this.$refs.imageSequence
      if(this.isVertical == true) {
        elToScroll = this.$parent.$parent.$refs.mainContainer
      }*/
    },
  },
  computed: {
    textPageClasses() {
      return{
        'static': this.accordionPosition == 'static',
        'farDown': this.accordionPosition == 'farDown',
        'stuckVisible': this.accordionPosition == 'stuckVisible',
        'stuckBottom': this.accordionPosition == 'stuckBottom',
      }
    },
    activePage() {
      let slug = this.$route.params.slug
      var filteredArray = this.pagesData.filter(function(page){
        return page.slug == slug
      })
      return filteredArray[0]
    },
    accordions() {
      //transforms unconnected sections into accordions
      if(this.activePage) {
        let i;
        let accInitArr = this.activePage.accordionSection
        let accArr = [] //should make an array of accordions.
        for (i = 0; i < accInitArr.length; i++) {
          if(accInitArr[i].__typename == 'accordionSection_accordionTitle_BlockType') {
            let newAccSect = []
            newAccSect.push(accInitArr[i])
            accArr.push(newAccSect)
          } else {
            //add to existing last el
            if(accInitArr[accArr.length - 1]) {
              accArr[accArr.length - 1].push(accInitArr[i])
            } else {
              //if there is no title and it's the only thing
              let blankTitle = {
                accordionTitle: "&nbsp;",
                id: "0",
                __typename: "accordionSection_accordionTitle_BlockType"
              }
              let newAccSect = []
              newAccSect.push(blankTitle)
              accArr.push(newAccSect)
              accArr[accArr.length - 1].push(accInitArr[i])
            }
          }
        }
        return accArr
      } else {
        return null
      }
    }
  },
  methods: {
    uniquePageName() {
      return this.$route.params.slug
    },
    showAccordions() {
      this.accordionPosition = 'stuckBottom'
    },
    downAccordions() {
      //let topBarHt = this.$parent.$refs.verticalMenu.$el.offsetHeight
      //if(this.$parent.$refs.mainContainer.scrollTop + topBarHt > this.$refs.imageSequencePage.$el.offsetTop) {
      if(this.$parent.$refs.mainContainer.scrollTop > 0) {
        this.accordionPosition = 'stuckVisible'
      }
    }
  },
  mounted() {
    //set active page and scroll to top
    document.getElementById('main-container').scrollTop = 0;
    //if the route is architektenplaene, redirect to google drive.
    if(this.$route.name == 'Plans') {
      window.location.href="https://drive.google.com/drive/folders/1T6aiYtfOohTTv0cZr4BLPpT9Afjt4YIF"
    }
  }

}
</script>

<style scoped>
/*.page {
  height: 100%;
  overflow: scroll;
}*/
@media (min-aspect-ratio: 12/10) {
  .page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow: hidden;
  }
  .imageSequence, .textPage {
    overflow-y: scroll;
    width: 100%;
  }
  .text-container {
    padding: 0 0.3rem;
  }
}
@media (max-aspect-ratio: 12/10) {
  .textPage {
    position: fixed;
    bottom: 0;
    transition: all 0.3s;
    width: 100%;
    background-color: #fff;
    max-height: calc(100% - 20vw + 3px);
    overflow-y: auto;
    transform: translateY(100%);
  }
  .textPage.stuckVisible {
    transform: translateY(calc(100% - 4.5rem));
  }
  .textPage.stuckBottom {
    transform: translateY(0);
  }
}
.textPage { overflow-x: hidden; }
</style>
