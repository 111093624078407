import gql from 'graphql-tag'

export default gql`
query navData {
  navData: globalSet {
    ... on navigation_GlobalSet {
      navWohnen {
        ...on navWohnen_link_BlockType {
          linkTitle
          linkPath
        }
      }
      navGenossenschaft {
        ...on navGenossenschaft_link_BlockType {
          linkTitle
          linkPath
        }
      }
      newsDirectLink
      navNews {
        ...on navNews_link_BlockType {
          linkTitle
          linkPath
        }
      }
    }
  }
}`