<template>
  <div class="intro">
    <div class="bigtext" v-if="bigText">{{ bigText }}</div>
    <div class="smalltext" v-if="smallText">{{ smallText }}</div>
  </div>
</template>

<script>

export default {
  name: 'Intro',
  props: {
    bigText: {
      required: true,
    },
    smallText: {
      required: false,
    }
  },
}
</script>

<style scoped>
.bigtext {
  font-size: 1.65rem;
  margin-bottom: 1rem;
  line-height: 1.15;
}
.smalltext {
  margin-bottom: 1rem;
}
.intro {
  margin-bottom: 2rem;
}
@media(max-aspect-ratio: 12/10) {
  .intro {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
  }
  .bigtext { font-size: 1.5rem; }
}

</style>
