import gql from 'graphql-tag'

export default gql`
query pagesData {
  pagesData: entries(section: "pages") {
    ... on pages_pages_Entry {
      id
      title
      slug
      includeInMenu
      intro
      introSmall
      accordionSection {
        __typename
        ... on accordionSection_accordionTitle_BlockType {
          id
          accordionTitle
        }
        ... on accordionSection_accordionImage_BlockType {
          id
          accordionImage {
            ...mediaInfo
          }
        }
        ... on accordionSection_accordionText_BlockType {
          id
          nrCols
          accordionText
        }
        ... on accordionSection_documents_BlockType {
          id
          documents {
            title
            url
            mimeType
            size
            extension
          }
        }
      }
      pageImgs {
        ... on uploads_Asset {
          ...mediaInfo
          legende
          imgTag
        }
      }
    }
  }
}

fragment mediaInfo on AssetInterface {
  title
  url @transform(handle: "large")
  filename
  mimeType
  focalPoint
  height
  width
}
`
