<template>
  <div
    id="app"
    :style="{ height: window.height + 'px' }"
    :class="[
      currentPageType,
      { showing: showing },
      isBau,
      isRepair,
      isNews,
      { isExhibit: isExhibit },
    ]"
  >
    <siteHeader v-if="!isVertical" ref="topBar" />
    <Menu
      v-show="isVertical"
      :pagesData="pagesData"
      :homeData="homeData"
      :isVertical="isVertical"
      :isHome="isHome"
      :window="window"
      ref="verticalMenu"
      :showNews="showNews"
      :navData="navData"
    />
    <!--bau is using this-->
    <div id="main-container" ref="mainContainer" v-if="!isBau && !isRepair">
      <!--<Home v-if="!isVertical && isHome" :homeData="homeData" />-->
      <transition name="slideup" v-if="!isVertical">
        <!--transition per route https://router.vuejs.org/guide/advanced/transitions.html#per-route-transition-->
        <router-view
          v-if="!(isHome && isVertical)"
          :homeData="homeData"
          :pagesData="pagesData"
          :isVertical="isVertical"
          :key="uniqueKey"
          ref="routerView"
          :showNews="showNews"
          :newsData="newsData"
        ></router-view>
        <!--cant be wrapped in keep alive or transitions stop working for same routes-->
      </transition>
      <router-view
        v-else-if="!(isHome && isVertical)"
        :homeData="homeData"
        :pagesData="pagesData"
        :isVertical="isVertical"
        :key="uniqueKey"
        ref="routerView"
        :showNews="showNews"
        :newsData="newsData"
      ></router-view>
      <!--cant be wrapped in keep alive or transitions stop working for same routes-->
    </div>
    <div id="main-container" ref="mainContainer" v-if="isBau">
      <!--special for bau webcam no transitions or weird stuff-->
      <router-view
        :homeData="homeData"
        :pagesData="pagesData"
        :isVertical="isVertical"
        :key="uniqueKey"
        ref="routerView"
        :showNews="showNews"
        :newsData="newsData"
      ></router-view>
      <!--cant be wrapped in keep alive or transitions stop working for same routes-->
    </div>
    <div id="main-container" ref="mainContainer" v-if="isRepair">
      <!--trying to get repair to not reload form on resize-->
      <router-view
        :homeData="homeData"
        :pagesData="pagesData"
        :isVertical="isVertical"
        :key="uniqueKey"
        ref="routerView"
        :showNews="showNews"
        :newsData="newsData"
      ></router-view>
    </div>
    <Menu
      v-show="!isVertical"
      :pagesData="pagesData"
      :homeData="homeData"
      :isVertical="isVertical"
      :window="window"
      :isHome="isHome"
      :navData="navData"
    />
  </div>
</template>

<script>
import siteHeader from "./components/Header.vue";
import Home from "./components/Home.vue";
import Menu from "./components/Menu.vue";

import homeData from "./queries/homeData.js";
import pagesData from "./queries/pagesData.js";
import navData from "./queries/nav.js";
import newsData from "./queries/news.js";

import smoothscroll from "smoothscroll-polyfill";
if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

export default {
  name: "App",
  components: {
    siteHeader,
    Home,
    Menu,
  },
  data: function () {
    return {
      window: {
        width: 0,
        height: 0,
      },
      homeData: [],
      pagesData: [],
      navData: [],
      newsData: [],
      showing: false,
      showNews: false,
      isExhibit: false,
    };
  },
  apollo: {
    homeData: {
      query: homeData,
    },
    pagesData: {
      query: pagesData,
    },
    navData: {
      query: navData,
    },
    newsData: {
      query: newsData,
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == "Home") {
        //this.$refs.routerView.classList()
        //hide news if not home.
        this.showNews = true;
      } else {
        this.showNews = false;
      }
      /*f(this.$route.path == '/100-jahre-bgw') { this.isExhibit = true }*/
      if (to.path == "/100-jahre-bgw") {
        this.isExhibit = true;
      } else {
        this.isExhibit = false;
      }
    },
  },
  computed: {
    isVertical() {
      //should generally be pretty horizontal before splitting into two panes.
      return this.window.width < this.window.height * 1.2;
    },
    currentPageType() {
      return this.$route && this.$route.name
        ? this.$route.name.toLowerCase()
        : "";
    },
    isHome() {
      return this.$route.name == "Home";
    },
    isBau() {
      return this.$route.name == "Webcam" ? "singlepage" : "";
    },
    isRepair() {
      return this.$route.name == "Reparaturen" ? "singlepage" : "";
    },
    isNews() {
      return this.$route.name == "News" ? "singlepage" : "";
    },
    uniqueKey() {
      return this.isHome ? "home" : this.$route.params.slug;
    },
  },
  methods: {
    onResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    //fade in
    setTimeout(
      function () {
        this.showing = true;
      }.bind(this),
      380
    );
    //scroll once to news.
    let that = this;
    setTimeout(function () {
      //show news if home.
      if (that.$route.name == "Home") {
        //that.isVertical ? that.$refs.verticalMenu.$refs.verticalHome.showNews = true : that.$refs.routerView.showNews = true
        that.showNews = true;
      }
    }, 300);
    /*setTimeout(function () {
      if (that.$route.name == "Home") {
        //this.wEls[i].$el.offsetTop
        const myHome = document.getElementById("home-and-news");
        if (myHome.scrollTop < 10) {
          //that.$refs.routerView.showNews = true
          const myEl = document.getElementById("news");
          myEl.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 4000);*/

    // use router links
    window.addEventListener("click", (event) => {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = event;
      while (target && target.tagName !== "A") target = target.parentNode;
      // handle only links that do not reference external resources
      if (
        target &&
        target.matches("a:not([href*='://'])") &&
        target.matches("a:not([href*='mailto'])") &&
        target.href
      ) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        const to = url.pathname;
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(to);
        }
      }
    });

    if (this.$route.path == "/100-jahre-bgw") {
      this.isExhibit = true;
    }
  },
};
</script>

<style>
html {
  font-size: 1.5vw;
  line-height: 1.2;
  margin: 0;
  hyphens: auto;
}
td {
  padding-right: 0.25rem;
  font-variant-numeric: tabular-nums;
}
td:first-of-type {
  text-align: right;
}
@media (max-width: 1350px) {
  html {
    font-size: 20px;
  }
}
body {
  margin: 0;
}
html,
body {
  overflow: hidden;
}
#app {
  font-family: "Surt", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  transition: 1s;
}
#app.showing {
  opacity: 1;
}
#main-container {
  overflow-y: auto;
}
@font-face {
  font-family: "Surt";
  src: url("~@/assets/Surt-Regular.woff2") format("woff2"),
    url("~@/assets/Surt-Regular.woff") format("woff"),
    url("~@/assets/Surt-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Surt";
  src: url("~@/assets/Surt-RegularOblique.woff2") format("woff2"),
    url("~@/assets/Surt-RegularOblique.woff") format("woff"),
    url("~@/assets/Surt-RegularOblique.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  color: #000 !important;
}
p a {
  text-decoration: underline !important;
}
a[href*="mailto"] {
  text-decoration: none !important;
}

@media (min-aspect-ratio: 12/10) {
  #app {
    display: grid;
    grid-template-rows: 7vw 1fr 5vw;
  }
}
@media (max-aspect-ratio: 12/10) {
  #app {
    display: grid;
    grid-template-rows: 20vw 1fr;
  }
  #app.home {
    grid-template-rows: 1fr;
  }
  #main-container {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
}

/*should switch to per-route transition, if i can figure out how to get props to home from router*/
.slideup-enter-active,
.slideup-leave-active,
.home-and-news.slideup-enter-active,
.home-and-news.slideup-leave-active {
  transition: transform 0.5s;
  /*transform: translate(0, 0);*/
  /*for transitions*/
  position: absolute;
  height: calc(100% - 12vw);
  z-index: -500;
}
.home-and-news.slideup-leave-active.slideup-leave-to {
  height: calc(100vh - 12vw);
}
.slideup-leave-active {
}
.slideup-enter-active {
}
.slideup-enter {
  transform: translate(0, 100%);
}
.slideup-leave-to {
  transform: translate(0, -100%);
}

/*exhibit einladung*/
.isExhibit {
  background: #fffa00;
}
.isExhibit img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  mix-blend-mode: multiply;
}
.isExhibit .header {
  background: #fffa00;
}
@media (min-aspect-ratio: 12 / 10) {
  .isExhibit .menu {
    background-color: #fffa00;
  }
  .isExhibit img {
    position: relative;
    z-index: 1;
  }
}
@media (max-aspect-ratio: 12 / 10) {
  .isExhibit .wlogo .background {
    opacity: 1;
  }
  .isExhibit .home-and-news,
  .isExhibit .textPage {
    background-color: #fffa00 !important;
  }
}
.newsitem p {
  margin: 0;
}
.newsitem strong {
  text-transform: uppercase;
  font-weight: 400;
}
.isExhibit .submenu {
  background-color: #fffa00 !important;
}
</style>
