import gql from 'graphql-tag'

export default gql`
query homeData {
  homeData: entry( section: "home" ) {
    ... on home_home_Entry {
      id
      title
      news
      homeImgs {
        ...mediaInfo
      }
    }
  }
}

fragment mediaInfo on AssetInterface {
  title
  url @transform(handle: "large")
  filename
  mimeType
  focalPoint
}
`
