<template>
  <div class="imageSequence" ref="imageSequence">
    <Wlogo v-if="startWithW" class="short first" ref="wElFirst" />
    <div class="container" v-for="(img, index) in pageImgsWithWs" :class="[img.proportion]" >
      <Wlogo v-if="img.Wlogo && img.WlogoAlign == 'left'" :class="[img.WlogoShortOrLong, img.WlogoAlign ]" ref="wElLeft" />
      <div class="img-and-caption" :class="[img.shortOrLong, img.proportion]">
        <img :src="img.url" />
        <div class="caption" v-if="img.legende !== null"><div class="legendpadding">{{ img.legende }}</div></div>
      </div>
      <Wlogo v-if="img.Wlogo && img.WlogoAlign == 'right' && index < pageImgsWithWs.length - 1" :class="[img.WlogoShortOrLong, img.WlogoAlign ]" ref="wElRight" />
    </div>
  </div>
</template>

<script>
import Wlogo from './Wlogo.vue'
//import _ from 'lodash'

export default {
  name: 'imageSequence',
  props: {
    pageImgs: {
      required: true
    },
    isVertical: {
      required: true
    }
  },
  components: {
    Wlogo,
  },
  data: function() {
    return {
      startWithW: false,
    }
  },
  computed: {
    pageImgsWithWs() {
      let pageImgsTransformed = []
      let i
      /*let rand = Math.random()
      let shortOrLong = rand < 0.5 ? 'short' : 'long'*/ //not sure if this should really be random or by path so constant.
      let shortOrLong = (this.$route.params.slug == 'genossenschaftlich' || this.$route.params.slug == 'wohnen' || this.$route.params.slug == '100-jahre-bgw') ? 'short' : 'long'
      let Wlogo = false
      let WlogoAlign = 'left'
      for (i = 0; i < this.pageImgs.length; i++) {
        let transformedEl = this.pageImgs[i]
        transformedEl.Wlogo = true
        //no W one out of every 3 times.
        i % 3 === 0 ? transformedEl.Wlogo = false : transformedEl.Wlogo = true
        WlogoAlign == 'left' ? WlogoAlign = 'right' : WlogoAlign = 'left'
        transformedEl.WlogoAlign = WlogoAlign
        shortOrLong == 'short' ? shortOrLong = 'long' : shortOrLong = 'short'
        //w opposite length as img
        shortOrLong == 'short' ? transformedEl.WlogoShortOrLong = 'short' : transformedEl.WlogoShortOrLong = 'long'
        if(transformedEl.width > transformedEl.height) {
          transformedEl.shortOrLong = shortOrLong
          transformedEl.proportion = 'landscape'
        } else {
          transformedEl.shortOrLong = 'short'
          transformedEl.proportion = 'portrait'
        }
        pageImgsTransformed.push(transformedEl)
      }
      return pageImgsTransformed
    },
    wEls() {
      /*console.log(this.$refs.wElLeft)
      if(this.$refs.wElFirst) {
        wEls.push(this.$refs.wElFirst)
      }
      console.log(wEls)
      if(this.$refs.wElLeft) {
        wEls.concat(this.$refs.wElLeft)
      }
      console.log(wEls)*/
      let firstEl = []
      if(this.$refs.wElFirst) {
        firstEl.push(this.$refs.wElFirst)
      }
      let wEls = [...firstEl, ...this.$refs.wElLeft, ...this.$refs.wElRight]
      return wEls
    },
  },
  methods: {
    startWithWMethod() {
      if(this.$route.params.slug == 'genossenschaftlich' || this.$route.params.slug == '100-jahre-bgw') {
        this.startWithW = true
      }
    },
    morphWs(event) {
      //calculates when W reaches the top and does decreases height.
      //console.log(this.wEls)
      //console.log(this.$refs.imageSequence.scrollTop)
      //need to have a var hieght of top bar where '90' is.
      let i
      for (i = 0; i < this.wEls.length; i++) {
        //works
        //this.wEls[i].$el.style.backgroundColor = "red"
        //get top bar ht
        let topBarHt = 0
        if(this.$parent.$parent.$refs.topBar) {
          topBarHt = this.$parent.$parent.$refs.topBar.$el.offsetHeight
        } else {
          topBarHt = this.$parent.$parent.$refs.verticalMenu.$el.offsetHeight
        }
        //get el to scroll
        let elToScroll = this.$refs.imageSequence
        if(this.isVertical == true) {
          elToScroll = this.$parent.$parent.$refs.mainContainer
        }
        let visibleW = this.wEls[i].$el.children[1]
        visibleW.style.width = this.wEls[i].$el.firstChild.getBoundingClientRect().width + 'px'
        //if the el hits the top, morph it.
        if(elToScroll.scrollTop + topBarHt > this.wEls[i].$el.offsetTop && (elToScroll.scrollTop + topBarHt < (this.wEls[i].$el.offsetTop + this.wEls[i].$el.offsetHeight))) {
          //switching to a system where there is an inivisible W that regulates the amount of space left before the next image, and a visible W that is fixed in position to try to be smoother.
          //this.wEls[i].$el.style.backgroundColor = "red"
          //sets top position of W
          this.wEls[i].$el.firstChild.style.top = (this.wEls[i].$el.offsetHeight - (this.wEls[i].$el.offsetHeight - elToScroll.scrollTop + this.wEls[i].$el.offsetTop - topBarHt)) + 'px'
          //sets top position of visible w and makes visible
          visibleW.style.top = topBarHt + 'px'
          visibleW.style.position = 'fixed'
          //sents height of W
          this.wEls[i].$el.firstChild.style.height = this.wEls[i].$el.offsetHeight - elToScroll.scrollTop + this.wEls[i].$el.offsetTop - topBarHt + 'px'
          visibleW.style.height = this.wEls[i].$el.offsetHeight - elToScroll.scrollTop + this.wEls[i].$el.offsetTop - topBarHt + 'px'
          //check that if you're scrolling fast, if you pass 0 you just make the el go away
          if(elToScroll.scrollTop + topBarHt > (this.wEls[i].$el.offsetTop + this.wEls[i].$el.offsetHeight)) {
            //this.wEls[i].$el.style.backgroundColor = "purple"
            this.wEls[i].$el.firstChild.style.top = 0
            this.wEls[i].$el.firstChild.style.height = '0'
            visibleW.style.top = 0
            visibleW.style.height = '0'
          }
        } else {
          //this.wEls[i].$el.style.backgroundColor = "green"
          this.wEls[i].$el.firstChild.style.top = 0
          this.wEls[i].$el.firstChild.style.height = '100%'
          visibleW.style.top = 0
          visibleW.style.position = 'absolute'
          visibleW.style.height = '100%'
        }
      }
    },
    showAccordions(event) {
      let topBarHt = this.$parent.$parent.$refs.verticalMenu.$el.offsetHeight
      //I used to show accordion when pictures were showing, but now I just show them as soon as you scroll.
      //if(this.$parent.$parent.$refs.mainContainer.scrollTop + topBarHt > this.$refs.imageSequence.offsetTop) {
      if(this.$parent.$parent.$refs.mainContainer.scrollTop > 0){
        //this.$refs.imageSequence.style.backgroundColor = "red"
        //console.log(this.$parent)
        if(this.$parent.accordionPosition == 'static' || this.$parent.accordionPosition == 'farDown') {
          this.$parent.accordionPosition = 'stuckVisible'
        }
        //this.$parent.$refs.textPage.style.bottom = "0px"
      } else {
        //this.$refs.imageSequence.style.backgroundColor = "green"
        this.$parent.accordionPosition = 'farDown'
        //this.$parent.$refs.textPage.style.bottom = "-500px"
      }
    }
  },
  mounted() {
    this.startWithWMethod()
    this.$refs.imageSequence.addEventListener('scroll', this.morphWs);
    this.$parent.$parent.$refs.mainContainer.addEventListener('scroll', this.morphWs);
    /*    this.$refs.imageSequence.addEventListener('scroll', _.debounce(this.morphWs, 10));
        this.$parent.$parent.$refs.mainContainer.addEventListener('scroll', _.debounce(this.morphWs, 10));
*/
    this.$parent.$parent.$refs.mainContainer.addEventListener('scroll', this.showAccordions);
  }
}

/*
sequence 1
w
horizontal 100
horizontal 60 right

sequence 2
w + vert img
horizontal 60 left
vert + w
horizontal 60 right
w wide
horizotal 100%

if it's horizontal, it shoudl alternate big small right left
if it's vertical it should alternate right left
there should be a wide or narrow W over teh whole width after every 3? horizontal 100%s.

*/
</script>

<style scoped>
.imageSequence {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.container {
  display: contents;
}
img {
    width: 100%;
    height: auto;
    height: intrinsic;
    display: block;
}
.img-and-caption {
  width: 100%;
  position: relative;
}
.caption {
    position: absolute;
    z-index: 800;
    bottom: 0;
    background-color: #fff;
    //height: 100%;
    width: 100%;
    color: #000;
    opacity: 0;
    transition: all 0.3s;
    white-space: pre-wrap;
    //text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.legendpadding { padding: 0.3rem; }
.img-and-caption:hover .caption{
  opacity: 1;
}
.short {
  width: 60%;
}
.short.landscape {
  width: 75%;
}
.wlogo.short {
  width: 40%;
  height: auto;
}
.wlogo.long {
  height: calc(100vh - 12vw);
}
.landscape .wlogo.short {
  width: 25%;
}
.wlogo.short.first{
  width: 25vw;
  height: 15vw;
}
/*>>> .wlogo.short.first .visiblew {
  width: 25vw;
  height: 15vw;
}*/
@media (max-aspect-ratio: 12/10) {
  /*if first div is short, put it over the whole width.*/
  .imageSequence > div:first-of-type .img-and-caption {
    width: 100%;
  }
  .wlogo.short.first {
    width: 50vw;
    height: 30vw;
  }
  .imageSequence {
    padding-bottom: 4.5rem;
  }
}
</style>
