<template>
  <div class="repairform">
      <h1>Reparaturformular</h1>
        <iframe id="repairform" class="repairform" :class="{loading: loadingIframe}" src="https://repairform.bgwiedikon.ch/form/" @load="notLoading()"></iframe>
        <div class="iframe-loading" v-if="loadingIframe == true">Lädt ...</div>
  </div>
</template>

<script>

export default {
  name: 'Repairform',
  data() {
    return {
      loadingIframe: true,
    }
  },
  methods: {
    notLoading() {
      this.loadingIframe = false
    }
  },
  created() {
    window.onmessage = (e) => {
      if (e.data.hasOwnProperty("frameHeight")) {
        document.getElementById("repairform").style.height = `${e.data.frameHeight + 30}px`;
      }
    };
  }
}
</script>

<style scoped>

.repairform {
  width: 100%;
  border: none;
  padding: 0.5rem;
  box-sizing: border-box;
}
h1 { padding-left: 0.4rem; margin-bottom: 0.3rem; }
.iframe-loading { padding-left: 0.4rem; }
#repairform {
}
.repairform.loading {
  height: 0;
  min-height: 0;
}
.iframe-loading {
  //background-color: red;
}
</style>
