import gql from 'graphql-tag'

export default gql`
query newsData {
  newsData: entry(section:"news") {
    ...on news_news_Entry {
      newsMatrix {
        ...on newsMatrix_newsItem_BlockType {
          id
          newsImg {
              title
            url @transform(handle: "large")
            filename
            mimeType
            focalPoint
            height
            width
          }
          newsText
        }
      }
    }
  }
}`