<template>
  <div class="newspage">
    <div class="container" v-if="newsData.newsMatrix">
      <vue-masonry-wall
        :items="newsData.newsMatrix"
        :options="{ width: 400, padding: 0 }"
      >
        <template v-slot:default="{ item }">
          <div class="newsitem">
            <img
              v-if="item.newsImg && item.newsImg.length > 0"
              :src="item.newsImg[0].url"
            />
            <div class="text" v-html="item.newsText"></div>
          </div>
        </template>
      </vue-masonry-wall>
    </div>
  </div>
</template>
  
  <script>
import VueMasonryWall from "vue-masonry-wall";
export default {
  name: "NewsPage",
  props: ["newsData"],
  mounted() {
    console.log(this.newsData.newsMatrix);
  },
};
</script>
  
  <style scoped>
img {
  max-width: 100%;
}
.text {
  padding: 0.5rem 0.5rem;
}
@media (min-aspect-ratio: 12/10) {
  .newspage {
    /*display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;*/
  }
}
.container {
  width: 100vw;
}
</style>
  