<template>
  <div class="wlogo">
    <div
      class="background"
      :style="{ backgroundImage: 'url(' + (imgInfo ? imgInfo.url : '') + ')', backgroundPosition: (imgInfo ? (imgInfo.focalPoint[0]*100 + '% ' + imgInfo.focalPoint[1]*100 + '%') : '50% 50%') }"
    >
  </div>
  <div
    class="visiblew"
    :style="{ backgroundImage: 'url(' + (imgInfo ? imgInfo.url : '') + ')', backgroundPosition: (imgInfo ? (imgInfo.focalPoint[0]*100 + '% ' + imgInfo.focalPoint[1]*100 + '%') : '50% 50%') }"
  ></div>
  </div>
</template>

<script>

export default {
  name: 'Wlogo',
  props: {
    imgInfo: {
      required: false,
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.wlogo {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -1px;
}
.background, .visiblew {
  clip-path: polygon(0% 0%, 30% 100%, 50% 32%, 70% 100%, 100% 0%);
  background-color: #000;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
}
.background {
  opacity: 0;
}
.visiblew {
  position: absolute;
  top: 0;
  background-color: #000;
  z-index: -100;
}
.home .background { opacity: 1; }
.home .visiblew { display: none; }
@media(min-width: 700px) {
  .background {
    opacity: 1;
  }
  .visiblew {
    opacity: 0;
  }
}
</style>
